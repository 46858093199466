<template>
  <el-dialog :close-on-click-modal="false" title="商城套餐" :visible.sync="shopVisible" width="650px"
             :before-close="closeEvent">
    <div class="mi-dv">
      <div class="mid-left">
        <span v-for="(item,index) in shopNames" :class="{spanIndex:index==spanIndex}" @click="setName(index,item.memberItems)">{{item.name}}</span>
      </div>
      <div class="mid-right">
          <div class="mi-item" v-for="item in memberItems">
            <div class="mi-con" @click="setMemberItem(item)">
              <span class="mci-name">{{item.itemName}}</span>
              <span class="mci-price">余 {{item.total-item.uses}} 次</span>
            </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
  export default {
    data() {
      return {
        spanIndex:0,
        shopNames:[],
        memberItems:[],
      }
    },
    props: {
      shopVisible: Boolean,
      memberId: String
    },
    created() {
      this.getShopItems()
    },
    methods: {
      //获取用户商城套餐
      async getShopItems() {
        let res = await this.$get("/sw/getSwShopItems", {memberId: this.memberId});
        this.shopNames = res.names
        if (this.shopNames.length>0){
          this.memberItems=this.shopNames[this.spanIndex].memberItems
        }
      },
      setName(index,memberItems){
        this.spanIndex=index
        this.memberItems=memberItems
      },
      closeEvent() {
        this.$emit('closeShop')
      },
      setMemberItem(item) {
        if (parseInt(item.total-item.uses)>0){
          item.uses=item.uses+1
          this.$emit('setShopItem', item)
        }else {
          this.$message.error("数量不足")
          return;
        }
      },
    }
  }
</script>
<style lang="scss">
  .mi-dv {
    display: flex;
    flex-direction: row;
    height: 380px;
  }

  .mid-left {
    width: 25%;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    height: 380px;
    border-right: #EEEEEE solid 1px;
  }
  .mid-left span{
    height: 25px;
    line-height: 25px;
    cursor: pointer;
    margin-bottom: 5px;
    border-bottom: #EEEEEE solid 1px;
    text-align: center;
  }
  .mid-left .spanIndex{
    color: #ff4d51;
  }

  .mid-right {
    width: 75%;
    /*display: flex;*/
    /*flex-flow: row wrap;*/
    /*overflow-y: scroll;*/
    height: 380px;
  }
  .mi-item {
    display: inline-block;
    height: 68px;
    padding: 0 8px 0px 8px;
    width: calc(100% / 4); // 我这里一行显示4个 所以是/4  一行显示几个就除以几
    min-width: calc(100% / 4);
    max-width: calc(100% / 4);
    &:nth-child(4n + 4) {
      margin-right: 0px;
    }
  }

  .mi-con {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    text-align: center;
    border: #EEEEEE solid 1px;
    padding: 5px 0;
  }

  .mc-img {
    width: 100%;
    height: 78px;
    border-radius: 3px;
  }

  .mci-name {
    margin-top: 3px;
    line-height: 16px;
    margin-left: 5px;
    font-size: 10px;
    margin-bottom: 5px;
  }

  .mci-price {
    line-height: 16px;
    margin-left: 5px;
    font-size: 10px;
  }

</style>
