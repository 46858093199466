<template>
  <div>
    <el-dialog :close-on-click-modal="false" title="套餐管理" :visible.sync="miVisible" width="650px"
               :before-close="closeEvent">
      <div class="mi-dv">
        <div class="mid-left">
          <span v-for="(item,index) in miNames" :class="{spanIndex:index==spanIndex}" @click="setName(index,item.memberItems)">{{item.ascriptionName}}</span>
        </div>
        <div class="mid-right">
          <div class="mi-item" v-for="item in memberItems">
            <div class="mi-con" @click="toMemberItem(item)">
              <span class="mci-name">{{item.itemName}}</span>
              <span class="mci-price">余 {{item.total-item.uses}} 次</span>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog :title="tcItem.itemName" :visible.sync="numVisible" width="300px">
      <div style="margin: 10px">
        <span style="margin-right: 8px">数量:</span>
        <el-input-number v-model="tcNum" :min="1" :max="maxNum"size="mini"></el-input-number>
      </div>
      <div style="height: 40px">
        <el-button size="small" type="primary" style="float: right" @click="setMemberItem">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        spanIndex:0,
        miNames:[],
        memberItems:[],
        numVisible:false,
        tcNum:1,
        maxNum:'',
        tcItem:{},
      }
    },
    props: {
      miVisible: Boolean,
      memberId: String
    },
    created() {
      this.getMemberItems()
    },
    methods: {
      //获取用户套餐项目
      async getMemberItems() {
        let res = await this.$get("/sw/getSwMemberItems", {memberId: this.memberId});
        this.miNames = res.names
        if (this.miNames.length>0){
          this.memberItems=this.miNames[this.spanIndex].memberItems
        }
      },
      setName(index,memberItems){
        this.spanIndex=index
        this.memberItems=memberItems
      },
      closeEvent() {
        this.$emit('closeMI')
      },
      toMemberItem(item){
        this.tcItem=item
        this.maxNum=item.total-item.uses
        this.numVisible=true
      },

      setMemberItem(item) {
        let data={
          tcNum:this.tcNum,
          item:this.tcItem,
        }
          this.$emit('setMemberItem', data)
      },
    }
  }
</script>
<style lang="scss">
  .mi-dv {
    display: flex;
    flex-direction: row;
    height: 380px;
  }

  .mid-left {
    width: 25%;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    height: 380px;
    border-right: #EEEEEE solid 1px;
  }
  .mid-left span{
    height: 25px;
    line-height: 25px;
    cursor: pointer;
    margin-bottom: 5px;
    border-bottom: #EEEEEE solid 1px;
    text-align: center;
  }
  .mid-left .spanIndex{
    color: #ff4d51;
  }

  .mid-right {
    width: 75%;
    /*display: flex;*/
    /*flex-flow: row wrap;*/
    /*overflow-y: scroll;*/
    height: 380px;
  }
  .mi-item {
    display: inline-block;
    height: 68px;
    padding: 0 8px 0px 8px;
    width: calc(100% / 4); // 我这里一行显示4个 所以是/4  一行显示几个就除以几
    min-width: calc(100% / 4);
    max-width: calc(100% / 4);
    &:nth-child(4n + 4) {
      margin-right: 0px;
    }
  }

  .mi-con {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    text-align: center;
    border: #EEEEEE solid 1px;
    padding: 5px 0;
  }

  .mc-img {
    width: 100%;
    height: 78px;
    border-radius: 3px;
  }

  .mci-name {
    margin-top: 3px;
    line-height: 16px;
    margin-left: 5px;
    font-size: 10px;
    margin-bottom: 5px;
  }

  .mci-price {
    line-height: 16px;
    margin-left: 5px;
    font-size: 10px;
  }

</style>
